<template>
  <div>
    <!-- Button -->
    <div class="top-table-container">
      <el-input v-model="search" prefix-icon="el-icon-search" class="search" placeholder="Buscar por palabra clave..." />
      <el-button @click.prevent="formNuevo">Nueva Comuna</el-button>
    </div>

    <!-- Table -->
    <el-table empty-text="Sin Resultados" :data="comunasData" border style="width: 100%">
      <el-table-column prop="id" width="50" label="ID" align="center"> </el-table-column>
      <el-table-column prop="nombre" label="Nombre" sortable></el-table-column>
      <el-table-column prop="region_nombre" label="Región"></el-table-column>
      <el-table-column align="center" prop="esta_vigente" label="Vigente" width="120">
        <template slot-scope="scope">
          <el-tag :type="scope.row.esta_vigente == '1' ? 'success' : 'danger'" disable-tranSitions>
            <template v-if="scope.row.esta_vigente == '1'">{{ "Si" }}</template>
            <template v-else>{{ "No" }}</template>
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="Acciones" width="220" align="center">
        <template slot-scope="scope">
          <el-button size="mini" icon="el-icon-edit" @click.prevent="formEditar(scope.$index, scope.row)">Editar</el-button>
          <el-button size="mini" type="danger" icon="el-icon-delete" @click.prevent="eliminar(scope.$index, scope.row)">Eliminar</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="paginate">
      <el-pagination background layout="prev, pager, next" @current-change="paginate" :page-size="pageSize" :total="total"> </el-pagination>
    </div>

    <!-- Modal => Nuevo - Editar -->
    <el-dialog :title="tituloModal" :visible.sync="modal" width="35%" top="10vh" :close-on-click-modal="false" :close-on-press-escape="false">
      <el-form :model="comuna" :rules="rules" ref="comuna" label-position="top">
        <el-row :gutter="16">
          <el-col :span="12">
            <el-form-item prop="id" label="ID" v-show="operacion == 'editar'">
              <el-input :disabled="true" v-model="comuna.id"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="operacion == 'editar' ? 12 : 24">
            <el-form-item label="Región" prop="region_id">
              <el-select no-match-text="Sin resultados" no-data-text="Sin datos" v-model="comuna.region_id" placeholder="Seleccione una región para agregar la comuna">
                <el-option v-for="region in regionesOpciones" :key="region.id" :label="region.nombre" :value="region.id"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="operacion == 'editar' ? 12 : 24" v-show="comuna.region_id">
            <el-form-item label="Nombre" prop="nombre">
              <el-input v-model="comuna.nombre" placeholder="Nombre de la comuna"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="Esta Vigente" v-show="operacion == 'editar'" prop="esta_vigente">
              <el-switch v-model="comuna.esta_vigente" active-color="#13ce66" inactive-color="#ff4949"> </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click.prevent="modal = false">{{ "Cancelar" }}</el-button>
        <el-button v-loading.fullscreen.lock="loading" type="primary" @click.prevent="guardar('comuna')">{{ accion }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from "@/axios";

export default {
  name: "Comunas",
  data() {
    return {
      modal: false,
      search: "",
      page: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      operacion: "",
      accion: "",
      tituloModal: "",
      regionesOpciones: [],
      comunas: [],
      comuna: {
        id: "",
        nombre: "",
        region_id: "",
        region_nombre: "",
        esta_vigente: true,
      },
      rules: {
        nombre: [{ required: true, message: "Este campo es requerido.", trigger: "blur" }],
        region_id: [{ required: true, message: "Este campo es requerido.", trigger: "change" }],
      },
    };
  },
  created() {
    this.optenerRegiones();
  },
  computed: {
    searching() {
      if (!this.search) {
        this.total = this.comunas.length;
        return this.comunas;
      }
      this.page = 1;
      return this.comunas.filter(data => data.nombre.toLowerCase().includes(this.search.toLowerCase()) || data.region_nombre.toLowerCase().includes(this.search.toLowerCase()));
    },
    comunasData() {
      this.total = this.searching.length;
      return this.searching.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page);
    },
  },
  methods: {
    // MÉTODOS PARA EL CRUD
    mostrar() {
      axios
        .get("comunas")
        .then(
          res =>
            (this.comunas = res.data.map(element => {
              const region_nombre = this.regionesOpciones.filter(dato => element.region_id == dato.id)[0].nombre;

              return {
                id: element.id,
                nombre: element.nombre,
                region_id: element.region_id,
                region_nombre: region_nombre,
                esta_vigente: element.esta_vigente,
              };
            }))
        )
        .catch(err => {
          console.log(err);
          this.$notify.error({
            title: "Error",
            dangerouslyUseHTMLString: true,
            message: `No se ha podido mostrar la data, ah ocurrido un error inesperado`,
          });
        });
    },
    crear() {
      const datos = {
        nombre: this.comuna.nombre,
        region_id: this.comuna.region_id,
        esta_vigente: this.comuna.esta_vigente,
      };
      axios
        .post("comunas", datos)
        .then(res => {
          if (res) {
            this.loading = true;
            setTimeout(() => {
              this.loading = false;
              this.$notify({
                title: "Éxito",
                dangerouslyUseHTMLString: true,
                message: `<strong>${datos.nombre}</strong> ha sido <strong>creada</strong> con exito!`,
                type: "success",
              });
              this.mostrar();
            }, 1500);
          }
        })
        .catch(err => {
          console.log(err);
          this.$notify.error({
            title: "Error",
            dangerouslyUseHTMLString: true,
            message: `No se ha podido crear la comuna, ah ocurrido un error inesperado`,
          });
        });
      this.comuna.nombre = "";
      this.comuna.region = "";
    },
    editar() {
      const id = this.comuna.id;
      const datos = {
        nombre: this.comuna.nombre,
        region_id: this.comuna.region_id,
        esta_vigente: this.comuna.esta_vigente,
      };
      axios
        .put(`comunas/${id}`, datos)
        .then(res => {
          if (res) {
            this.loading = true;
            setTimeout(() => {
              this.loading = false;
              this.$notify({
                title: "Éxito",
                dangerouslyUseHTMLString: true,
                message: `<strong>${datos.nombre}</strong> ha sido <strong>actualizada</strong> con exito!`,
                type: "success",
              });
              this.mostrar();
            }, 1500);
          }
        })
        .catch(err => {
          console.log(err);
          this.$notify.error({
            title: "Error",
            dangerouslyUseHTMLString: true,
            message: `No se ha podido editar la comuna, ah ocurrido un error inesperado`,
          });
        });
    },
    eliminar(_, data) {
      const id = data.id;
      this.$confirm("Esta seguro que desea eliminar este registro?", "Eliminar Comuna", {
        confirmButtonText: "Si",
        cancelButtonText: "Cancelar",
        type: "warning",
      })
        .then(res => {
          if (res) {
            axios
              .delete(`comunas/${id}`)
              .then(res => {
                if (res) {
                  this.loading = true;
                  setTimeout(() => {
                    this.loading = false;
                    this.$notify({
                      title: "Éxito",
                      dangerouslyUseHTMLString: true,
                      message: `<strong>${data.nombre}</strong> ha sido <strong>eliminada</strong> con exito!`,
                      type: "success",
                    });
                    this.mostrar();
                  }, 1500);
                }
              })
              .catch(err => {
                console.log(err);
                this.$notify.error({
                  title: "Error",
                  dangerouslyUseHTMLString: true,
                  message: `No se ha podido eliminar la comuna, ah ocurrido un error inesperado`,
                });
              });
          }
        })
        .catch(err => console.log(err));
    },
    optenerRegiones() {
      axios
        .get("regiones")
        .then(res => {
          this.regionesOpciones = res.data;
          this.mostrar();
        })
        .catch(err => {
          console.log(err);
          this.$notify.error({
            title: "Error",
            dangerouslyUseHTMLString: true,
            message: `No se ha podido mostrar la data, ah ocurrido un error inesperado`,
          });
        });
    },
    //Botones y Formularios
    guardar(comuna) {
      this.$refs[comuna].validate(valid => {
        if (valid) {
          if (this.operacion == "crear") this.crear();
          if (this.operacion == "editar") this.editar();
          this.modal = false;
          this.$refs[comuna].resetFields();
        } else {
          return false;
        }
      });
    },
    formNuevo() {
      this.modal = true;
      this.operacion = "crear";
      this.accion = "Guardar";
      this.tituloModal = "Nueva Comuna";
      this.comuna.region_id = "";
      this.comuna.nombre = "";
      this.comuna.nombre = "";
      if (typeof this.$refs.comuna != "undefined") {
        this.$refs.comuna.resetFields();
      }
    },
    formEditar(_, data) {
      //capturamos los datos del registro seleccionado y los mostramos en el formulario
      this.modal = true;
      this.operacion = "editar";
      this.accion = "Actualizar";
      this.tituloModal = "Editar Comuna";
      this.comuna.id = data.id;
      this.comuna.nombre = data.nombre;
      this.comuna.region_id = data.region_id;
      this.comuna.esta_vigente = data.esta_vigente == "1" ? true : false;
      if (typeof this.$refs.comuna != "undefined") {
        this.$refs.comuna.clearValidate();
      }
    },
    // Paginación
    paginate(val) {
      this.page = val;
    },
  },
};
</script>

<style></style>
